<template>
  <button @click="showDetails" class="text-center" :disabled="isDisabled">
    <div
      class="text-3xl"
      :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-green-400']"
    >
      <FileSearchOutlined />
    </div>
    <p>Detalles</p>
  </button>

  <button @click="handleEdit" class="text-center" :disabled="isDisabled">
    <div
      class="text-3xl"
      :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
    >
      <FormOutlined />
    </div>
    <p>Editar</p>
  </button>

  <button @click="showActividades" class="text-center" :disabled="isDisabled">
    <div
      class="text-3xl"
      :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-400']"
    >
      <BookOutlined />
    </div>
    <p>Atenciones</p>
  </button>

  <button @click="applyExportar" class="text-center">
    <div class="text-3xl text-green-700">
      <FileExcelOutlined />
    </div>
    <p>Exportar</p>
  </button>

  <!-- <button @click="applyExportar" class="text-center">
    <div class="text-3xl text-green-700">
      <FileExcelOutlined />
    </div>
    <p>Reporte</p>
  </button> -->
</template>

<script>
import {
  FileSearchOutlined,
  FormOutlined,
  BookOutlined,
  FileExcelOutlined
} from "@ant-design/icons-vue";
import { computed, ref } from "vue";

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  components: {
    FileSearchOutlined,
    FormOutlined,
    BookOutlined,
    FileExcelOutlined
  },
  emits: ["showDetails", "handleEdit", "showActividades", "applyExportar"],
  setup(props, { emit }) {
    return {
      showDetails: () => emit("showDetails"),
      handleEdit: () => emit("handleEdit"),
      showActividades: () => emit("showActividades"),
      applyExportar: () => emit("applyExportar")
    };
  }
};
</script>

<style></style>
