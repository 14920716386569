export const columnsPacientesNinios = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Documento",
    dataIndex: "documento",
    key: "documento",
    slots: { customRender: "documento" },
    width: 80
  },
  {
    title: "Nombres",
    dataIndex: "nombre_completo",
    key: "nombre_completo",
    slots: { customRender: "nombrecompleto" },
    width: 220
  },
  {
    title: "Fecha Nac.",
    dataIndex: "fechanacimiento_f",
    key: "fechanacimiento_f",
    width: 100
  },
  {
    title: "Dirección",
    dataIndex: "direccion",
    key: "direccion",
    width: 300
  },
  {
    title: "Sexo",
    dataIndex: "idsexo",
    key: "idsexo",
    slots: { customRender: "idsexo" },
    width: 50
  }
];

export const columnsPacientesGestante = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Documento",
    dataIndex: "documento",
    key: "documento",
    slots: { customRender: "documento" },
    width: 80
  },
  {
    title: "Nombre",
    dataIndex: "nombre_completo",
    key: "nombre_completo",
    slots: { customRender: "nombrecompleto" },
    width: 200
  },
  {
    title: "Dirección",
    dataIndex: "direccion",
    key: "direccion",
    width: 300
  },
  {
    title: "Fecha Parto",
    dataIndex: "fecnacido",
    key: "fecnacido",
    width: 100
  }
];

export const columnsPacienteAtencion = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Fecha de atención",
    dataIndex: "fecha_atencion",
    key: "fecha_atencion",
    width: 90
  },
  {
    title: "Establecimiento",
    dataIndex: "establecimiento",
    key: "establecimiento",
    width: 180
  },
  {
    title: "Código RENAES",
    dataIndex: "codrenaes",
    key: "codrenaes",
    width: 90,
    slots: { customRender: "codrenaes" }
  }
  // {
  //   title: "",
  //   key: "acciones",
  //   width: 50,
  //   slots: { customRender: "acciones" }
  // }
];

export const columnsPacienteActividades = [
  { title: "Servicio", dataIndex: "servicio", key: "servicio", width: 160 },
  { title: "Actividad", dataIndex: "actividad", key: "actividad", width: 220 },
  { title: "Lote", dataIndex: "lote", key: "lote", width: 50 },
  { title: "Nro Pag.", dataIndex: "num_pag", key: "num_pag", width: 50 },
  { title: "Nro Reg.", dataIndex: "num_reg", key: "num_reg", width: 50 },
  { title: "Personal", dataIndex: "personal", key: "personal", width: 180 }
];

export const agrupaPorFechaYServicio = (actividades) => {
  // creo un nuevo indice

  const actividadesWithIdCompuesto = actividades.map((actividad) => ({
    ...actividad,
    idcompuesto: actividad.fecha_atencion + actividad.establecimiento
  }));

  const atenciones = actividadesWithIdCompuesto.reduce((previousValue, currentValue) => {
    var i = previousValue.findIndex((x) => x.idcompuesto === currentValue.idcompuesto);

    if (i === -1) {
      previousValue.push({
        idcompuesto: currentValue.idcompuesto,
        establecimiento: currentValue.establecimiento,
        fecha_atencion: currentValue.fecha_atencion,
        codrenaes: currentValue.codreanes
      });
    }
    return previousValue;
  }, []);

  return {
    dataActividades: actividadesWithIdCompuesto,
    dataAtenciones: atenciones
  };
};
