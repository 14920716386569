<template>
  <a-modal
    :visible="true"
    title="DETALLE DE PACIENTE"
    class="max-w-sm md:max-w-lg"
    width="100%"
    :footer="false"
    @close="closeModal"
    @cancel="closeModal"
  >
    <a-list size="small">
      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Apellido paterno</span>
        <span class="font-bold md:font-normal">{{ paciente?.apellidopaterno }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Apellido materno</span>
        <span class="font-bold md:font-normal">{{ paciente.apellidomaterno }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Nombres</span>
        <span class="font-bold md:font-normal">{{ paciente.nombre }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Nro Documento</span>
        <span class="font-bold md:font-normal">{{ paciente.documento }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Sexo</span>
        <a-tag :color="paciente.idsexo === '1' ? 'blue' : 'pink'">
          {{ paciente.idsexo === "1" ? "Hombre" : "Mujer" }}
        </a-tag>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Fecha de nacimiento</span>
        <span class="font-bold md:font-normal">{{ paciente.fechanacimiento_f }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Dirección</span>
        <span class="font-bold md:font-normal">{{ paciente.direccion }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Establecimiento</span>
        <span class="font-bold md:font-normal">{{ paciente.establecimiento }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">{{ isPacienteNinio ? "Madre" : "Hijo(a)" }}</span>

        <span v-if="isPacienteNinio" class="font-bold md:font-normal">
          {{ parienteExterno[0] }}
        </span>

        <span v-else>
          <ul class="list-disc">
            <li v-for="(pariente, index) in parienteExterno" :key="index">
              {{ pariente }}
            </li>
          </ul>
        </span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold text-right"
          >Nro Documento {{ isPacienteNinio ? "de madre" : "de hijo(a)" }}</span
        >
        <span class="font-bold md:font-normal">{{ paciente.documentoexterno }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Celular</span>
        <span class="font-bold md:font-normal">{{ paciente.celular }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Dirección 2</span>
        <span class="font-bold md:font-normal">{{ paciente.direccion2 }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Celular 2</span>
        <span class="font-bold md:font-normal">{{ paciente.celular2 }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Correo</span>
        <span class="font-bold md:font-normal">{{ paciente.email }}</span>
      </a-list-item>
    </a-list>
  </a-modal>
</template>

<script>
import { onMounted, ref } from "vue";
import PacienteApi from "@/api/paciente";

export default {
  props: {
    paciente: {
      type: Object,
      default: null
    },
    isPacienteNinio: {
      type: Boolean,
      default: true
    }
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const parienteExterno = ref([]);

    const closeModal = () => {
      emit("closeModal");
    };

    onMounted(() => {
      const documento = props.isPacienteNinio
        ? props.paciente.documentoexterno
        : props.paciente.documento;

      PacienteApi.getParienteExterno(props.isPacienteNinio, documento)
        .then((res) => {
          console.log(res.data);
          parienteExterno.value = res.data.map((pariente) => pariente.nombre_externo);
        })
        .catch((err) => {
          parienteExterno.value = ["NO REGISTRADO"];
        });
    });

    return {
      closeModal,
      parienteExterno
    };
  }
};
</script>
