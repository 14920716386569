<template>
  <div style="display: none">
    <div id="printPacienteActividadDetalle">
      <PrintContainer tituloReporte="DETALLES DE ATENCIONES DEL PACIENTE">
        <div class="flex w-full border border-black mb-4 p-3">
          <div class="w-1/2">
            <h5 class="px-2 py-1">
              <b>PACIENTE: </b>
              {{ paciente.nombre_completo }}
            </h5>
            <h5 class="px-2 py-1">
              <b>NRO. DOCUMENTO: </b>
              {{ paciente.documento }}
            </h5>
            <h5 class="px-2 py-1">
              <b>DIRECCIÓN: </b>
              {{ paciente.direccion }}
            </h5>
            <h5 class="px-2 py-1">
              <b>SEXO: </b>
              <a-tag :color="paciente.idsexo === '1' ? 'blue' : 'pink'">
                {{ paciente.idsexo === "1" ? "Hombre" : "Mujer" }}
              </a-tag>
            </h5>
          </div>

          <div class="w-1/2">
            <h5 class="px-2 py-1">
              <b>ESTABLECIMIENTO: </b>
              {{ paciente.establecimiento }}
            </h5>
            <h5 class="px-2 py-1">
              <b>FECHA DE NACIMIENTO: </b>
              {{ paciente.fechanacimiento_f }}
            </h5>
            <h5 class="flex px-2 py-1">
              <b>{{ isPacienteNinio ? "MADRE:" : "HIJO(A): " }}</b>
              <p v-if="isPacienteNinio">{{ parienteExterno[0] }}</p>
              <div v-else>
                <ul class="ml-6 list-disc">
                  <li v-for="(pariente, index) in parienteExterno" :key="index">
                    {{ pariente }}
                  </li>
                </ul>
              </div>
            </h5>
            <h5 class="px-2 py-1">
              <b>CELULAR: </b>
              {{ paciente.celular }}
            </h5>
          </div>
        </div>

        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">FECHA DE ATENCIÓN</th>
              <th class="text-center p-3 border border-black">SERVICIO</th>
              <th class="text-center p-3 border border-black">ACTIVIDAD</th>
              <th class="text-center p-3 border border-black">LOTE</th>
              <th class="text-center p-3 border border-black">NRO PAG.</th>
              <th class="text-center p-3 border border-black">NRO REG.</th>
              <th class="text-center p-3 border border-black">ESTABLECIMIENTO</th>
              <th class="text-center p-3 border border-black">PERSONAL</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(actividad, index) in actividades" :key="index">
              <td class="border border-gray-900 align-middle text-center py-2">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ actividad.fecha_atencion }}
              </td>
              <td class="border border-gray-900 align-left px-2">
                {{ actividad.servicio }}
              </td>
              <td class="border border-gray-900 align-left px-2">
                {{ actividad.actividad }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ actividad.lote }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ actividad.num_pag }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ actividad.num_reg }}
              </td>
              <td class="border border-gray-900 text-left px-2">
                {{ actividad.establecimiento }}
              </td>
              <td class="border border-gray-900 text-left px-2">
                {{ actividad.personal }}
              </td>
            </tr>
          </tbody>
          <PrintFooter />
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";
import PacienteApi from "@/api/paciente";
import { onMounted, ref } from "vue";

export default {
  components: {
    PrintContainer,
    PrintFooter
  },
  props: {
    actividades: {
      type: Array,
      default: () => []
    },
    paciente: {
      type: Object,
      default: null
    },
    isPacienteNinio: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const parienteExterno = ref([]);

    onMounted(() => {
      const documento = props.isPacienteNinio
        ? props.paciente.documentoexterno
        : props.paciente.documento;

      PacienteApi.getParienteExterno(props.isPacienteNinio, documento)
        .then((res) => {
          console.log(res.data);
          parienteExterno.value = res.data.map((pariente) => pariente.nombre_externo);
        })
        .catch(() => {
          parienteExterno.value = ["NO REGISTRADO"];
        });
    });

    return {
      parienteExterno
    };
  }
};
</script>

<style scoped></style>
