<template>
  <a-modal
    :visible="true"
    class="w-full"
    width="100%"
    @close="closeModal"
    @cancel="closeModal"
    :footer="false"
  >
    <template #title>
      <div class="text-center flex flex-wrap justify-between items-center px-3 py-1 mr-5">
        <p class="font-semibold">ATENCIONES DEL PACIENTE: {{ paciente.nombre_completo }}</p>

        <div class="flex gap-3">
          <a-tooltip title="Descargar atenciones">
            <a-button
              v-print="printActividad"
              :loading="isLoading"
              class="text-red-500 hover:text-red-300"
            >
              <template #icon><FilePdfFilled /></template><span class="text-black">Atenciones</span>
            </a-button>
          </a-tooltip>

          <a-tooltip title="Descargar actividades">
            <a-button
              v-print="printActividadDetalle"
              :loading="isLoading"
              class="text-red-500 hover:text-red-300"
            >
              <template #icon><FilePdfFilled /></template
              ><span class="text-black">Actividades</span>
            </a-button>
          </a-tooltip>

          <a-tooltip>
            <template #title>Descargar actividades</template>
            <a-button
              shape="circle"
              @click="handleExcel"
              class="text-green-500 hover:text-green-300"
            >
              <template #icon><FileExcelFilled /></template>
            </a-button>
          </a-tooltip>
        </div>
      </div>
    </template>

    <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
      <a-table
        :columns="columnsPacienteAtencion"
        :data-source="atenciones"
        size="small"
        rowKey="idcompuesto"
        :loading="{ spinning: isLoading, tip: 'Cargando actividades, por favor espere' }"
        :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
        defaultExpandAllRows
      >
        <template #rangepages="{ record }">
          <span> {{ atenciones.indexOf(record) + 1 }} </span>
        </template>

        <template #expandedRowRender="{ record }">
          <a-table
            :columns="columnsPacienteActividades"
            size="small"
            rowKey="idcompuesto"
            :data-source="actividades.filter((data) => data.idcompuesto === record.idcompuesto)"
            defaultExpandAllRows
          >
          </a-table>
        </template>
      </a-table>
    </div>
    <PrintPacienteActividad :atenciones="atenciones" :paciente="paciente" />

    <PrintPacienteActividadDetalle
      :paciente="paciente"
      :actividades="actividades"
      :isPacienteNinio="isPacienteNinio"
    />
  </a-modal>
</template>

<script>
import {
  columnsPacienteAtencion,
  columnsPacienteActividades,
  agrupaPorFechaYServicio
} from "./utilsPacientes";
import PacienteApi from "@/api/paciente";
import PrintPacienteActividad from "@/print/PrintPacienteActividad.vue";
import PrintPacienteActividadDetalle from "@/print/PrintPacienteActividadDetalle.vue";
import { ref, onMounted, reactive, toRaw } from "vue";
import { FilePdfFilled, FileExcelFilled } from "@ant-design/icons-vue";
import ApiDescargas from "@/api/descargas";

export default {
  components: {
    PrintPacienteActividad,
    FileExcelFilled,
    PrintPacienteActividadDetalle,
    // icons
    FilePdfFilled
  },
  props: {
    paciente: {
      type: Object,
      default: null
    },
    isPacienteNinio: {
      type: Boolean,
      default: true
    }
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const actividades = ref([]);
    const atenciones = ref([]);
    const isLoading = ref(false);
    const printActividad = reactive({
      id: "printPacienteActividad",
      popTitle: "SIVINUR | LISTA DE ACTIVIDADES"
    });

    const printActividadDetalle = reactive({
      id: "printPacienteActividadDetalle",
      popTitle: "SIVINUR | DETALLE DE ACTIVIDAD DE PACIENTE"
    });

    const fetchActividades = () => {
      isLoading.value = true;
      PacienteApi.getActividad(props.paciente.documento)
        .then((response) => {
          const actividadesSinKey = response.data;
          const { dataActividades, dataAtenciones } = agrupaPorFechaYServicio(actividadesSinKey);
          console.log(dataActividades, dataAtenciones);
          atenciones.value = dataAtenciones;
          actividades.value = dataActividades;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
        });
    };

    onMounted(() => {
      fetchActividades();
    });

    const closeModal = () => {
      emit("closeModal");
    };

    function handleExcel() {
      var dataatenciones = toRaw(atenciones.value);
      var dataactividades = toRaw(actividades.value);

      ApiDescargas.getReporteAcciones(dataatenciones, dataactividades)
        .then(() => {
          console.log("dsecarga");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      closeModal,
      atenciones,
      actividades,
      columnsPacienteAtencion,
      columnsPacienteActividades,
      isLoading,
      handleExcel,
      printActividad,
      printActividadDetalle
    };
  }
};
</script>
