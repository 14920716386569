<template>
  <PageHeader
    :title="tituloPaginaUppercase"
    :level1="{ title: 'Registro', route: '/menu' }"
    :level2="{ title: tituloPagina, route: '/' }"
    goBack
  />

  <div class="flex flex-wrap mb-4">
    <fieldset
      class="border border-solid border-gray-300 justify-center items-center w-full flex px-8 lg:w-auto"
    >
      <legend class="text-sm text-left pl-4">Filtros</legend>
      <PacientesFiltros @reloadFetch="reloadFetch" />
    </fieldset>

    <!--------------- Acciones--------------->
    <fieldset
      class="border border-solid w-full mt-3 border-gray-300 p-3 px-8 flex justify-center md:mt-0 lg:w-auto"
    >
      <legend class="text-sm text-center">Acciones</legend>
      <div class="flex flex-wrap gap-4">
        <div class="flex flex-wrap gap-8 md:gap-5">
          <PacientesAcciones
            :isDisabled="isDisabled"
            @showDetails="showDetails"
            @handleEdit="handleEdit"
            @showActividades="showActividades"
            @applyExportar="applyExportar"
          />
        </div>
      </div>
    </fieldset>

    <fieldset
      class="border border-solid w-full mt-3 border-gray-300 p-3 px-8 flex justify-center md:mt-0 lg:w-auto"
    >
      <legend class="text-sm text-center">Fecha de corte</legend>
      <a-form class="md:flex w-full flex-col gap-4" ref="formRefFilter" :model="formState">
        <a-form-item
          v-if="isPacienteNinio"
          label="Filtrar por edad (tienen)"
          name="search"
          class="mb-0 w-full"
        >
          <a-select class="w-40" v-model:value="formState.dia_inicio">
            <a-select-option value="0">0 meses</a-select-option>
            <a-select-option value="30">1 mes</a-select-option>
            <a-select-option value="60">2 meses</a-select-option>
            <a-select-option value="91">3 meses</a-select-option>
            <a-select-option value="121">4 meses</a-select-option>
            <a-select-option value="152">5 meses</a-select-option>
            <a-select-option value="182">6 meses</a-select-option>
            <a-select-option value="212">7 meses</a-select-option>
            <a-select-option value="243">8 meses</a-select-option>
            <a-select-option value="273">9 meses</a-select-option>
            <a-select-option value="304">10 meses</a-select-option>
            <a-select-option value="334">11 meses</a-select-option>
            <a-select-option value="365">1 año</a-select-option>
            <a-select-option value="730">2 años</a-select-option>
            <a-select-option value="1095">3 años</a-select-option>
            <a-select-option value="1460">4 años</a-select-option>
            <a-select-option value="1825">5 años</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="Fecha de proceso"
          name="fecha_corte"
          class="mb-4 w-full"
          v-if="isPacienteNinio"
        >
          <a-date-picker v-model:value="formState.fecha_corte" format="DD/MM/YYYY" />
          <a-button @click="applyFilters">Aplicar</a-button>
        </a-form-item>
      </a-form>
    </fieldset>
  </div>

  <a-spin tip="Descargando Reporte..." :spinning="spinning">
    <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="pacientesNinios"
        size="small"
        :pagination="false"
        :custom-row="customRow"
        rowKey="idpaciente"
        :loading="isLoading"
        :rowClassName="
          (record, index) =>
            index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
        "
      >
        <template #documento="{ record }">
          <a @click="showDetails"> {{ record.documento }} </a>
        </template>

        <template #nombrecompleto="{ record }">
          <p>{{ record?.apellidopaterno }} {{ record?.apellidomaterno }}, {{ record?.nombre }}</p>
        </template>

        <template #rangepages="{ index }">
          <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
        </template>

        <template #idsexo="{ text: idsexo }">
          <span>
            <a-tag :color="idsexo === '1' ? 'blue' : 'pink'">
              {{ idsexo === "1" ? "Hombre" : "Mujer" }}
            </a-tag>
          </span>
        </template>
      </a-table>
    </div>
  </a-spin>

  <FooterTotalDocs
    :currentPage="currentPage"
    :pageSize="pageSize"
    :totalDocs="totalDocs"
    @fetchData="fetchPacientes"
  />

  <FooterFuente fuente="PADRÓN NOMINAL, HISMINSA y CNV" :fecha="fechaIngreso" />

  <PacienteEdit
    v-if="isVisible"
    :isVisible="isVisible"
    :paciente="paciente"
    :currentPage="currentPage"
    :isPacienteNinio="isPacienteNinio"
    @closeModal="closeModal"
    @fetchPacientes="fetchPacientes"
    @clearSelectedArrays="clearSelectedArrays"
  />

  <PacientesDetalles
    v-if="isVisibleDetalle"
    :paciente="paciente"
    :isVisible="isVisibleDetalle"
    :isPacienteNinio="isPacienteNinio"
    @closeModal="closeModal"
  />

  <PacienteActividad
    v-if="isVisibleActividad"
    :paciente="paciente"
    :isVisible="isVisibleActividad"
    :isPacienteNinio="isPacienteNinio"
    @closeModal="closeModal"
  />
</template>

<script>
import { ref, watch, reactive, onMounted, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { getUsuario } from "@/utils/auth";
import { columnsPacientesNinios, columnsPacientesGestante } from "./utilsPacientes";
import PacienteApi from "@/api/paciente";
import PacienteEdit from "./PacientesEdit.vue";
import PacientesFiltros from "./PacientesFiltros.vue";
import PacientesDetalles from "./PacientesDetalles.vue";
import PacienteActividad from "./PacienteActividad.vue";
import PacientesAcciones from "./PacientesAcciones.vue";
import { useRoute } from "vue-router";
import UsuariosApi from "@/api/user";
import ApiDescargas from "@/api/descargas";
import PageHeader from "@/components/PageHeader.vue";
import FooterTotalDocs from "@/components/FooterTotalDocs.vue";
import FooterFuente from "@/components/FooterFuente.vue";
import { numberWithCommas } from "@/utils";
import moment from "moment";

export default {
  name: "AppPacientes",
  components: {
    PacientesFiltros,
    PacienteEdit,
    PacientesDetalles,
    PacienteActividad,
    PacientesAcciones,
    PageHeader,
    FooterTotalDocs,
    FooterFuente
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const currentPage = ref(1);
    const totalDocs = ref(null);
    const totalDocsWithCommas = ref("");
    const paciente = ref(null);
    const isVisible = ref(false);
    const spinning = ref(false);
    const isVisibleDetalle = ref(false);
    const isVisibleActividad = ref(false);
    const pacientesNinios = ref([]);
    const isLoading = ref(false);
    const isDisabled = ref(true);
    const idPaciente = ref("");
    const fechaIngreso = ref("");
    const formState = reactive({
      dia_inicio: "1825",
      dia_final: 1825 + 364,
      fecha_corte: moment()
    });

    const route = useRoute();
    const tipopaciente = route.params?.tipopaciente;

    const isPacienteNinio = tipopaciente === "ninios";
    const idtipopaciente = isPacienteNinio ? "1" : "2";

    const { establecimiento } = store.state.user.usuario;

    const state = reactive({
      selectedRowKeys: []
    });

    const tituloPagina = computed(() =>
      isPacienteNinio ? "Pacientes niños" : "Pacientes gestantes"
    );

    const tituloPaginaUppercase = computed(() =>
      isPacienteNinio ? "PACIENTES NIÑOS MENORES DE 5 AÑOS" : "PACIENTES GESTANTES"
    );

    // ------- trae datos de la api --------
    const fetchPacientes = (currentPage = 1) => {
      const departamento = "TACNA";
      const provincia = store.getters["paciente/provincia"];
      const distrito = store.getters["paciente/distrito"];
      const search = store.getters["paciente/search"];

      var idestablecimiento = getUsuario().idestablecimiento;
      if (idestablecimiento == -1 || idestablecimiento == -2) idestablecimiento = "%%";

      const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
      const dia_inicio = formState.dia_inicio;
      const dia_final =
        Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);

      console.log(idtipopaciente);

      isLoading.value = true;

      if (idtipopaciente == "1") {
        PacienteApi.getAllNiniosMenores5Anios(
          {
            currentPage,
            departamento,
            provincia,
            distrito,
            search,
            fecha_corte,
            dia_inicio,
            dia_final
          },
          idtipopaciente,
          idestablecimiento
        )
          .then((response) => {
            pacientesNinios.value = response.data.docs;

            totalDocs.value = response.data.totalDocs;
            totalDocsWithCommas.value = numberWithCommas(response.data.totalDocs);
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading.value = false));
      } else {
        PacienteApi.getAll(
          {
            currentPage,
            departamento,
            provincia,
            distrito,
            search
          },
          idtipopaciente,
          idestablecimiento
        )
          .then((response) => {
            pacientesNinios.value = response.data.docs;

            totalDocs.value = response.data.totalDocs;
            totalDocsWithCommas.value = numberWithCommas(response.data.totalDocs);
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading.value = false));
      }
    };

    const fetchFechaImportacion = () => {
      UsuariosApi.getFechaImportacion("maestro_paciente")
        .then((res) => {
          fechaIngreso.value =
            moment(res.data[0]?.fechaingreso).format("DD/MM/YYYY") || "No registrado";
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(() => {
      UsuariosApi.userLogAdd(
        "Registro",
        isPacienteNinio ? "Paciente Niños" : "Paciente Gestante",
        establecimiento
      );

      fetchPacientes();
      fetchFechaImportacion();
    });

    onUnmounted(() => {
      store.dispatch("paciente/cleanFilters");
    });

    watch(state, () => {
      isDisabled.value = !state.selectedRowKeys.length;
    });

    // -------- para selecionar los datos de la tabla --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          paciente.value = selectedRows[0];
        }
      };
    });

    const customRow = (record) => {
      return {
        onclick: () => {
          paciente.value = record;
          state.selectedRowKeys = [record.idpaciente];
        },
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    const handleEdit = () => {
      showModal();
    };
    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
      isVisibleDetalle.value = false;
      isVisibleActividad.value = false;
      clearSelectedArrays();
    };

    // -------- trae datos al cambiar de pagina --------
    watch(currentPage, () => {
      fetchPacientes(currentPage.value);
    });

    const reloadFetch = () => {
      currentPage.value = 1;
      fetchPacientes(currentPage.value);
    };

    const niniosFormated = computed(() =>
      pacientesNinios.value.map((d) => {
        return {
          ...d,
          fechanacimiento_f: moment(d.fechanacimiento).format("L")
        };
      })
    );

    const applyExportar = () => {
      spinning.value = true;
      const provincia = store.getters["paciente/provincia"];
      const distrito = store.getters["paciente/distrito"];
      const search = store.getters["paciente/search"];

      if (isPacienteNinio) {
        ApiDescargas.getAllNinios({ provincia, distrito, search })
          .then(() => {
            console.log("dsecarga");
          })
          .finally(() => (spinning.value = false));
      } else {
        ApiDescargas.getAllGestantes({ provincia, distrito, search })
          .then(() => {
            console.log("dsecarga");
          })
          .finally(() => (spinning.value = false));
      }
    };

    const showDetails = () => {
      isVisibleDetalle.value = true;
    };

    const showActividades = () => {
      isVisibleActividad.value = true;
    };

    // watch(formState, () => {
    //   const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
    //   const dia_inicio = formState.dia_inicio;
    //   const dia_final =
    //     Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);
    // });

    const applyFilters = () => {
      fetchPacientes();
    };

    return {
      tituloPagina,
      // routes: isPacienteNinio ? breadcrumbs.ninios : breadcrumbs.gestantes,
      isLoading,
      columns: isPacienteNinio ? columnsPacientesNinios : columnsPacientesGestante,
      pacientesNinios: niniosFormated,
      rowSelection,
      clearSelectedArrays,
      customRow,
      pageSize,
      handleEdit,
      currentPage,
      totalDocs,
      isVisible,
      spinning,
      closeModal,
      fetchPacientes,
      paciente,
      reloadFetch,
      totalDocsWithCommas,
      isDisabled,
      idPaciente,
      applyExportar,
      showDetails,
      isVisibleDetalle,
      showActividades,
      isVisibleActividad,
      isPacienteNinio,
      fechaIngreso,
      tituloPaginaUppercase,
      formState,
      applyFilters
    };
  }
};
</script>

<style></style>

<style scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}
</style>
