<template>
  <a-form
    class="md:flex w-full gap-4"
    ref="formRefFilter"
    layout="vertical"
    :model="formState"
    :rules="rules"
  >
    <div class="block lg:flex gap-4 w-full">
      <a-form-item label="Provincia" name="search" class="mb-0 w-full lg:w-44">
        <a-select v-model:value="formState.provincia" @change="handleProvincia">
          <a-select-option value="">TODAS</a-select-option>
          <a-select-option
            :value="provincia.provincia"
            v-for="provincia in provincias"
            :key="provincia.provincia"
          >
            {{ provincia.provincia }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Distrito" class="mb-0 w-full lg:w-44" name="search">
        <a-select v-model:value="formState.distrito">
          <a-select-option value="">TODOS</a-select-option>
          <a-select-option
            :value="distrito.distrito"
            v-for="distrito in distritos"
            :key="distrito.distrito"
          >
            {{ distrito.distrito }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>

    <div class="flex flex-col lg:flex-row w-full gap-4">
      <a-form-item label="Buscar" class="mb-0" name="search">
        <a-input v-model:value="formState.search" placeholder="Buscar paciente" />
      </a-form-item>

      <div class="flex gap-3">
        <button @click="applyFilters" type="submit" class="text-center">
          <div class="text-3xl text-blue-400">
            <SearchOutlined />
          </div>
          <p>Buscar</p>
        </button>

        <button @click="cleanFilters" type="button" class="text-center" :disabled="isDisabled">
          <div
            class="text-3xl"
            :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
          >
            <ClearOutlined />
          </div>
          <p>Limpiar filtros</p>
        </button>
      </div>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { SearchOutlined, ClearOutlined, FileSearchOutlined } from "@ant-design/icons-vue";
import EstablecimientoApi from "@/api/establecimiento";
import { useStore } from "vuex";

export default {
  components: {
    // icons
    SearchOutlined,
    ClearOutlined,
    FileSearchOutlined
  },
  emits: ["reloadFetch"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const provincias = ref([]);
    const distritos = ref([]);
    const isDisabled = ref(true);

    const formState = reactive({
      departamento: "TACNA",
      provincia: store.getters["paciente/provincia"],
      distrito: store.getters["paciente/distrito"],
      search: store.getters["paciente/search"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const getProvincias = (filtro) => {
      EstablecimientoApi.getAllProvincias(filtro)
        .then((response) => {
          provincias.value = response.data;
          formState.provincia = "";
          formState.distrito = "";
          getDistritos(formState.provincia);
        })
        .catch((err) => console.log(err));
    };

    const getDistritos = (filtro) => {
      EstablecimientoApi.getAllDistritos({ filtro })
        .then((response) => {
          distritos.value = response.data;
          formState.distrito = "";
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      getProvincias(formState.departamento);
      getDistritos(formState.provincia);
    });

    const handleProvincia = (value) => {
      store.dispatch("paciente/setdistrito", value);
      getDistritos(value);
    };

    const applyFilters = () => {
      formRefFilter.value
        .validate()
        .then(async () => {
          isDisabled.value = false;
          store.dispatch("paciente/setprovincia", formState.provincia);
          store.dispatch("paciente/setdistrito", formState.distrito);
          store.dispatch("paciente/setsearch", formState.search);
          emit("reloadFetch");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      formState.departamento = "";
      formState.provincia = "";
      formState.distrito = "";
      formState.search = "";
      distritos.value = [];
      store.dispatch("paciente/cleanFilters");
      emit("reloadFetch");
      isDisabled.value = true;
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      rules,
      provincias,
      handleProvincia,
      distritos,
      cleanFilters,
      isDisabled
    };
  }
};
</script>
