import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";

export default {
  getAll: (payload, idtipopaciente, idestablecimiento) => {
    const { provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "u.provincia|u.distrito|idtipopaciente|e.idestablecimiento",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%${idtipopaciente}%|${idestablecimiento}%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "CONCAT(apellidopaterno, ' ', apellidomaterno, ' ' , nombre)|documento",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if (payload.currentPage != undefined) {
      request._petitions.push({
        name: "@page",
        value: payload.currentPage,
        type: "int"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  getAllNiniosMenores5Anios: (payload, idtipopaciente, idestablecimiento) => {
    const { provincia, distrito, search, fecha_corte, dia_inicio, dia_final } = payload;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente_ninios_menores_5_anios",
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "string"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "u.provincia|u.distrito|idtipopaciente|e.idestablecimiento",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%${idtipopaciente}%|${idestablecimiento}%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "CONCAT(apellidopaterno, ' ', apellidomaterno, ' ' , nombre)|documento",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if (payload.currentPage != undefined) {
      request._petitions.push({
        name: "@page",
        value: payload.currentPage,
        type: "int"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  getAllNinios: (payload) => {
    const { provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "u.provincia|u.distrito|idtipopaciente",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%1%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "CONCAT(apellidopaterno, ' ', apellidomaterno, ' ' , nombre)|documento",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if (payload.currentPage != undefined) {
      request._petitions.push({
        name: "@page",
        value: payload.currentPage,
        type: "int"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  getAllGestantes: (payload) => {
    const { provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "u.provincia|u.distrito|idtipopaciente",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%2%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "CONCAT(apellidopaterno, ' ', apellidomaterno, ' ' , nombre)|documento",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if (payload.currentPage != undefined) {
      request._petitions.push({
        name: "@page",
        value: payload.currentPage,
        type: "int"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  editOne: (payload, idpaciente) => {
    const request = {
      _type: 3,
      _table: "reg_paciente",
      _petitions: [
        {
          name: "apellidopaterno",
          value: payload.apellidopaterno,
          type: "string"
        },
        {
          name: "apellidomaterno",
          value: payload.apellidomaterno,
          type: "string"
        },
        {
          name: "nombre",
          value: payload.nombre,
          type: "string"
        },
        {
          name: "celular",
          value: payload.celular,
          type: "string"
        },
        {
          name: "celular2",
          value: payload.celular2,
          type: "string"
        },
        {
          name: "email",
          value: payload.email,
          type: "string"
        },
        {
          name: "direccion",
          value: payload.direccion,
          type: "string"
        },
        {
          name: "direccion2",
          value: payload.direccion2,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idpaciente, request);
  },

  getActividad: (xdni) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "reg_consulta_nominal_trama",
          type: "string"
        },
        {
          name: "@DNI",
          value: xdni,
          type: "int"
        }
      ]
    };

    // CALL sivi_migr.reg_consulta_nominal_trama('12345678')

    console.log(request);

    return post(API_SERVICE_QUERY, request);
  },

  getParienteExterno: (isPacienteNinio, documentoExterno) => {
    const query = isPacienteNinio
      ? "web_data_select_paciente_documento_externo"
      : "web_data_select_paciente_documento";

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: query,
          type: "string"
        },
        {
          name: "@DOCUMENTOEXTERNO",
          value: documentoExterno,
          type: "int"
        }
      ]
    };

    // CALL sivi_migr.reg_consulta_nominal_trama('12345678')

    console.log(request);

    return post(API_SERVICE_QUERY, request);
  }
};
