<template>
  <div style="display: none">
    <div id="printPacienteActividad">
      <PrintContainer tituloReporte="ATENCIONES DEL PACIENTE">
        <div class="w-full border border-black mb-4 p-3">
          <h5>
            <b>NOMBRE COMPLETO: </b>
            {{ paciente.nombre_completo }}
          </h5>
          <h5 class="my-2">
            <b>NRO. DOCUMENTO: </b>
            {{ paciente.documento }}
          </h5>
          <h5>
            <b>DIRECCIÓN: </b>
            {{ paciente.direccion }}
          </h5>
        </div>

        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">FECHA ATENCIÓN</th>
              <th class="text-center p-3 border border-black">ESTABLECIMIENTO</th>
              <th class="text-center p-3 border border-black">CÓDIGO RENAES</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(atencion, index) in atenciones" :key="index">
              <td class="border border-gray-900 align-middle text-center py-2">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ atencion.fecha_atencion }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ atencion.establecimiento }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ atencion.codrenaes || "No registra" }}
              </td>
            </tr>
          </tbody>
          <PrintFooter />
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";

export default {
  components: {
    PrintContainer,
    PrintFooter
  },
  props: {
    atenciones: {
      type: Array,
      default: () => []
    },
    paciente: {
      type: Object,
      default: null
    }
  },
  setup() {
    return {};
  }
};
</script>

<style scoped></style>
