<template>
  <a-modal
    :visible="true"
    :title="isPacienteNinio ? 'Editar paciente niño(a)' : 'Editar paciente gestante'"
    class="max-w-sm md:max-w-xl"
    width="100%"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Apellido Paterno" name="apellidopaterno" class="w-full">
          <a-input v-model:value="formState.apellidopaterno" />
        </a-form-item>

        <a-form-item label="Apellido Materno" name="apellidomaterno" class="w-full">
          <a-input v-model:value="formState.apellidomaterno" />
        </a-form-item>
      </div>

      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Nombres" name="nombre" class="w-full">
          <a-input v-model:value="formState.nombre" />
        </a-form-item>

        <a-form-item label="Correo" name="email" class="w-full">
          <a-input v-model:value="formState.email" />
        </a-form-item>
      </div>

      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Celular 1" name="celular" class="w-full">
          <a-input v-model:value="formState.celular" />
        </a-form-item>

        <a-form-item label="Celular 2" name="celular2" class="w-full">
          <a-input v-model:value="formState.celular2" />
        </a-form-item>
      </div>

      <a-form-item label="Dirección 1" name="direccion">
        <a-textarea v-model:value="formState.direccion" />
      </a-form-item>

      <a-form-item label="Dirección 2" name="direccion2">
        <a-textarea v-model:value="formState.direccion2" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref, computed } from "vue";
import { notification } from "ant-design-vue";
import PacienteApi from "@/api/paciente";

export default {
  props: {
    paciente: {
      type: Object
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isPacienteNinio: {
      type: Boolean,
      default: true
    }
  },
  emits: ["closeModal", "fetchPacientes"],
  setup(props, { emit }) {
    const modulos = ref([]);
    const formRef = ref();
    /* eslint-disable  vue/no-setup-props-destructure*/
    const paciente = props.paciente; // se define porque tiene que ser unico en editar

    const formState = reactive(paciente);
    const currentPage = props.currentPage;

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          let response = {};
          let msgNotificacion = "";
          const payload = toRaw(formState);
          const idpaciente = toRaw(formState)?.idpaciente;

          response = await PacienteApi.editOne(payload, idpaciente);
          msgNotificacion = "El paciente ha sido modificado correctamente.";

          if (response.status == 200) {
            notification.success({
              message: "Operación exitosa",
              description: msgNotificacion
            });
          }
          emit("closeModal");
          emit("fetchPacientes", currentPage);
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("fetchPacientes", currentPage);
      emit("closeModal");
    };

    const rules = {
      celular: [
        {
          max: 9,
          message: "La cantidad máxima de caracteres es de 9",
          trigger: "blur"
        }
      ],
      email: [
        {
          type: "email",
          message: "Formato de correo inválido"
        }
      ],
      direccion: [
        {
          max: 400,
          message: "La cantidad máxima de caracteres es de 400",
          trigger: "blur"
        }
      ]
    };

    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      modulos,
      formRef,
      rules
    };
  }
};
</script>
